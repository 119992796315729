import { FC, HTMLAttributes, ReactNode } from "react";
import {
  DocumentHead,
  AppBarWeb,
  AppBarMobile,
  DustLabsFooter,
  Footer,
  ClientOnly,
} from "@shared/components";
import { PageMeta, ProjectConfig } from "@shared/types";
import { GLOBAL_CONFIG } from "@shared/constants";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import Image from "next/image";
import { useWindowSize } from "@dustlabs/web/hooks";
interface Props extends HTMLAttributes<HTMLDivElement> {
  pageMeta?: PageMeta;
  children: ReactNode;
  path: string;
  footer?: number;
  animation?: string;
  cancelAnimation?: boolean;
  forceCenter?: boolean;
}

const PageTemplate: FC<Props> = ({
  pageMeta,
  children,
  path,
  footer,
  animation,
  cancelAnimation,
  forceCenter = false,
  className,
  ...componentProps
}) => {
  const { ASSETS, FAVICON, BASE_URL, ORG_NAME } =
    GLOBAL_CONFIG as ProjectConfig;
  const router = useRouter();
  const isExplorer = router.pathname.includes("explorer");
  const isY00tsLanding = ORG_NAME !== "DeGods" && router.pathname === "/";
  const explorerPagesStyle =
    "relative y00ts:lg:mt-10 w-full flex-1 mx-auto lg:pt-20 y00ts:lg:pt-16 max-w-[1448px]";
  const nonExplorerStyles =
    "relative lg:mt-32 w-full flex-1 mx-auto overflow-x-hidden max-w-[1400px]";
  const isDeGodsLandingPage = ORG_NAME === "DeGods" && router.pathname === "/";
  const y00tsLandingPageStyle =
    "relative w-screen flex-1 flex justify-center overflow-x-hidden lg:mt-20";
  const appBarAnimations = {
    initial: { opacity: 0, zIndex: 100 },
    animate: {
      opacity: 1,
      transition: {
        delay: cancelAnimation ? 1 : 4,
        duration: cancelAnimation ? 1 : 2,
      },
    },
    exit: { opacity: 0, transition: { duration: 1 } },
  };

  const [winWidth, winHeight] = useWindowSize();

  const renderNav = () => (winWidth >= 1024 ? <AppBarWeb /> : <AppBarMobile />);

  return (
    <div
      {...componentProps}
      className={`flex flex-col w-full transition-colors ease-in-out  
      duration-700 bg-lightBackground ${className}`}
      style={{
        minHeight: winHeight,
      }}
    >
      <DocumentHead
        baseUrl={BASE_URL}
        pageMeta={pageMeta}
        assets={ASSETS}
        path={path}
        favicon={`${ASSETS}${FAVICON}`}
      />

      {/* without animation */}
      {!animation && renderNav()}

      {/* with animation */}
      <AnimatePresence exitBeforeEnter>
        {animation === "show" && (
          <motion.div {...appBarAnimations}>{renderNav()}</motion.div>
        )}
      </AnimatePresence>

      <main
        className={`${
          isExplorer
            ? explorerPagesStyle
            : isY00tsLanding
            ? y00tsLandingPageStyle
            : !isDeGodsLandingPage
            ? nonExplorerStyles
            : ""
        } ${
          forceCenter &&
          (isY00tsLanding
            ? "flex justify-center items-center h-fit md:h-screen pb-6"
            : !isDeGodsLandingPage
            ? "flex justify-center items-center xl:mt-14 heightSmall:mt-16 h-fit md:h-screen pb-6"
            : "flex flex-col justify-center items-center h-[calc(100vh-72px-72px)] lg:h-screen w-full")
        }`}
      >
        <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>
      </main>

      {footer && <>{footer === 1 ? <Footer /> : <DustLabsFooter />} </>}
    </div>
  );
};

export default PageTemplate;
